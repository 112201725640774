import { Ref, Label, Line, TextInput } from "@tblabs/truffle";
import { DeliveryForm } from "../../Models/Basket/DeliveryForm";
import { FormPaymentForm } from "../../Models/OrderTicket/PaymentForm";
import { Basket } from "../../Services/Basket/Basket";
import { BasketTable } from "../BasketTable";
import { DatesRangeInput } from "../Inputs/DatesRangeInput";
import { Box } from "../Utils/Box";
import { Center } from "../Utils/Center";
import { HashLink } from "../Utils/HashLink";
import { InputRow } from "../Utils/InputRow";
import { PrimaryButton } from "../Utils/PrimaryButton";
import { Section } from "../Utils/Section";
import { CustomerInput } from "./CustomerInput";
import { DeliveryFormSelector } from "./DeliveryFormSelector";
import { DepositReturnInfo } from "./DepositReturnInfo";
import { FormInfo } from "./FormInfo";
import { OrderFormView } from "./OrderFormView";
import { PaymentFormSelector } from "./PaymentFormSelector";
import { ReturnFormSelector } from "./ReturnFormSelector";


export class FormBox extends Box
{
    constructor(info: FormInfo, view: Ref<OrderFormView>)
    {
        const basket = info.basket;
        super(
            new Section("🗓️ Okres wypożyczenia", [
                new DatesRangeInput(basket.DatesRange)
            ]).Visible(basket.IsAnythingToRent, "block"),
            new Section("🛒 Twoje zamówienie", [
                new BasketTable(basket),
                new Box(
                    new Label(basket.TotalPrice, v => `Łącznie ${v} zł`).MarginRight(148)
                ).TextAlignRight().MarginTop(8),
                new Center(
                    new HashLink("➕ Dodaj produkty").Color("#1e87f0")
                ).MarginTop(20),
            ]),
            new Section("🚚 Odbiór / dostawa", [
                new DeliveryFormSelector(info.deliveryForm, info.place),
                new Line(),
                new PaymentFormSelector(info.paymentForm, info.allowCashPayment, basket.IsAnythingToRent),
                new Line().Visible(basket.IsAnythingToRent),
                new InputRow("💰 Kaucja", new Label(info.depositInfo))
                    .Visible(basket.IsAnythingToRent, "flex"),
                new Line(),
                new InputRow("🎓 Szkolenie", new Label(info.trainingInfo)),
            ]),
            new Section("📦 Zwrot", [
                new ReturnFormSelector(info.returnForm),
                new Line(),
                new DepositReturnInfo(info.depositReturnInfo, info.showCustomerDepositAccountInput),
            ]).Visible(basket.IsAnythingToRent, "block"),
            new Section("🤵 Twoje dane", [
                new CustomerInput(info.Customer),
            ]),
            new Section("🎈 Rabaty", [
                new InputRow("Kod rabatowy", new TextInput().Placeholder("Wpisz jeśli jakiś posiadasz")),
            ]),
            new Center(
                new PrimaryButton("Przejdź do podsumowania")
                    .EnableWhen([basket.Items.CountRef, info.deliveryForm, info.paymentForm],
                        (count, delivery, payment) => +count > 0
                            && delivery != DeliveryForm.Unset
                            && payment != FormPaymentForm.Unset)
                    .OnClick(async () =>
                    {
                        // this.PrepareSummary(preticket, info.customer, info.deliveryForm, info.returnForm, info.paymentForm, basket);
                        view.value = OrderFormView.Summary;
                    })
            ).Margin(32)
        );
    }
}
