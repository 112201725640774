import { Span, Line, UnorderedList, Snack, Link, Button, RefString, Label } from "@tblabs/truffle";
import { Header } from "../../Components/Utils/Header";
import { Row } from "../../Components/Utils/Row";
import { HashLink } from "../../Components/Utils/HashLink";
import { Center } from "../../Components/Utils/Center";
import { DescriptionViewer } from "../../Components/Description/DescriptionViewer";
import { AuthService } from "../../Services/Auth/AuthService";
import { Basket } from "../../Services/Basket/Basket";
import { Loader } from "../../Components/Utils/Loader";
import { SectionBox } from "../../Components/SectionBox";
import { Page } from "../Components/Page";
import { Gallery } from "../../Components/Description/Gallery/Gallery";
import { PageLogo } from "../Components/PageLogo";
import { BasketItemType } from "../../Models/Basket/BasketItemType";
import { Product } from "../../Models/Product/Product";
import { Table } from "@tblabs/truffle/Controls/TableBuilder/Table";
import { BreadcrumbsView } from "./BreadcrumbsView";
import { RentSection } from "./RentSection";
import { BasketableSection, SaleSection } from "./SaleSection";
import { WebsiteContentProvider } from "../../Services/Content/WebsiteContentProvider";
import { ProductOptionSelector } from "./ProductOptionSelector";


export class ProductPage extends Page
{
    private optionId = new RefString(this.optionIdFromUrl)
    private loader = new Loader()
    private content = new Row()

    constructor(
        private _websiteContentProvider: WebsiteContentProvider,
        private _basket: Basket,
        private _user: AuthService,
        private productUrl: string,
        private optionIdFromUrl?: string)
    {
        super();

        this.body.Append(
            new PageLogo(),
            this.loader,
            this.content,
        )
    }

    protected async OnAppend(): Promise<void>
    {
        const websiteContent = await this._websiteContentProvider.Get()
        // const product = websiteContent.Products.Items.find(x => x.Url.value == this.productUrl);
        const product = websiteContent.ProductByUrl(this.productUrl);

        if (!product)
        {
            new Snack("Produkt nie znaleziony!")
            return;
        }

        this.loader.RemoveSelf()

        this.content.Insert(
            new Header(
                this._user.IsAuthorized && new HashLink("🐓 Edytuj", `edit/product/${product.Url.value}`).FloatRight().FontSize(16),
                new BreadcrumbsView(product),
            ),
            new Row(
                new Table([], [
                    [
                        new SectionBox(
                            new Gallery(product.Images),
                        ),
                        new SectionBox(
                            new ProductOptionSelector(this.optionId, product),

                            product.Rentable.value && new RentSection(product, (p) => this.TryAddToBasket(BasketItemType.Rent, p), this.optionId),

                            (product.Rentable.value && product.Sailable.value) && new Line().Margin(12),

                            product.Sailable.value && new SaleSection(product, (p) => this.TryAddToBasket(BasketItemType.Sale, p), this.optionId),

                            (product.Sailable.value && product.Basketable.value) && new Line().Margin(12),
                            product.Basketable.value && new BasketableSection(product, (p) => this.TryAddToBasket(product.AddToBasketAs.value, p), this.optionId),
                        ).Width(340),
                    ],
                    [
                        new SectionBox(
                            new UnorderedList(product.Features.value.split("\n")).Color("#222").Width(380)
                        ).Height(200),
                        new SectionBox(
                            new Span(`🟢 Produkt dostępny`),
                            new Line().Margin(8),
                            new Span(`🏅 Ocena klientów`),
                            new Center(
                                new Span(`⭐⭐⭐⭐⭐ 4.92/5`),
                            ).Margin(8),
                            new Line().Margin(8),
                            `🚚 Dostawa od 1zł w 24h\n`,
                            `🕵️‍♂️ Pomoc techniczna 24/7`
                        ).Width(340).Height(200),
                    ]
                ]
                ).MarginAuto(),

                new DescriptionViewer(product.Description),

                new Line(),
                new Center(
                    product.Rentable.value && new Button("Wypożycz").Class("selected")
                        .OnClick(() => this.TryAddToBasket(BasketItemType.Rent, product)),
                    (product.Rentable.value || product.Sailable.value) && new Span("lub").Margin(12).Italic(),
                    product.Sailable.value && new Button("Kup").Class("selected")
                        .OnClick(() => this.TryAddToBasket(BasketItemType.Sale, product)),
                    product.Basketable.value && new Button("Dodaj do koszyka").Class("selected")
                        .OnClick(() => this.TryAddToBasket(product.AddToBasketAs.value, product)),
                ).Margin(32),
            )
        )
    }

    private ShowAddedSnack()
    {
        new Snack("🛒 Dodano do koszyka!",
            new Link("Otwórz koszyk")
                .OnClick(() => window.location.hash = "basket"))
    }


    private TryAddToBasket(type: BasketItemType, product: Product): void
    {
        if (!this._basket.CanAdd(type, product.Id, this.optionId.value))
        {
            new Snack("Produkt jest już w koszyku!")
            return;
        }

        this.ShowAddedSnack();

        this._basket.Add(type, product.Id, this.optionId.value);
    }
}
