
export enum FormPaymentForm
{
    Unset = "Unset",
    Cash = "Cash",
    Transfer = "Transfer",
    Crypto = "Crypto",
    DepositPrepaidServicePostpaid = "DepositPrepaidServicePostpaid",
    PartialDepositPrepaidServicePostpaid = "PartialDepositPrepaidServicePostpaid",
    FromPreviousOrder = "FromPreviousOrder",
    CashAtDelivery = "CashAtDelivery"
}
