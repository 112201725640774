import { Header } from "../../Components/Utils/Header";
import { HashLink } from "../../Components/Utils/HashLink";
import { ProductBox } from "../Product/Components/ProductBox";
import { WebsiteContentProvider } from "../../Services/Content/WebsiteContentProvider";
import { Row } from "../../Components/Utils/Row";
import { Loader } from "../../Components/Utils/Loader";
import { Features } from "../Components/Features";
import { AdminAddButton } from "../../Components/Admin/AdminAddButton";
import { Page } from "../Components/Page";
import { AuthService } from "../../Services/Auth/AuthService";
import { PageLogo } from "../Components/PageLogo";


export class CategoryPage extends Page
{
    private loader = new Loader()
    private content = new Row()

    constructor(private _content: WebsiteContentProvider, private _auth: AuthService, private categoryUrl: string)
    {
        super();

        this.body.Append(
            new PageLogo(),
            new Features(),
            this.loader,
            this.content,
        )
    }

    protected async OnAppend(): Promise<void>
    {
        const content = await this._content.Get();
        const category = content.CategoryByUrl(this.categoryUrl);
        const products = content.ProductsByCategory(category);

        this.loader.RemoveSelf()

        this.content.Insert(
            new Header(new HashLink("Oferta"), " > ", category.Title.value),
            products.length == 0 && "Brak produktów w tej kategorii",
            ...products?.map(x => new ProductBox(x)),
            this._auth.IsAuthorized && new AdminAddButton('add/product'),
        )
    }
}
