import { Div, Span } from "@tblabs/truffle";
import { HashLink } from "../../Components/Utils/HashLink";
import { Product } from "../../Models/Product/Product";


export class BreadcrumbsView extends Div
{
    constructor(product: Product)
    {
        super();

        this.Append(
            new HashLink("Oferta"),
            " > ", new HashLink(product.Category.Title.value, "category/" + product.Category.Url.value),
            " > ", new Span(product.Title.value).Style('font-weight: 600').Color("#fe8015")
        );
    }
}
