import { TicketBasket } from "./TicketBasket";
import { TicketMeta } from "./TicketMeta";
import { TicketPayment } from "./TicketPayment";
import { TicketPeople } from "./TicketPeople";
import { TicketTraining } from "./TicketTraining";
import { TicketTransit } from "./TicketTransit";


export class Ticket
{
    public Id = "";
    public Basket = new TicketBasket();
    public People = new TicketPeople();
    public Transit = new TicketTransit();
    public Training = new TicketTraining();
    public Payment = new TicketPayment();
    public Meta = new TicketMeta();

    constructor(id: string)
    {
        this.Id = id;
        this.Meta.Created = new Date();
        this.Meta.CreatedBy = window.location.hostname;
    }
}