import { Collection, RefBool, RefNumber } from "@tblabs/truffle";
import { Product } from "../../Models/Product/Product";
import { BasketItemType } from "../../Models/Basket/BasketItemType";
import { DatesRange } from "../../Models/Dates/DatesRange";
import { IBasketStorage } from "./IBasketStorage";
import { BasketStorageItem } from "./BasketStorageItem";
import { RentBasketItem } from "./RentBasketItem";
import { SaleBasketItem } from "./SaleBasketItem";
import { BasketItem } from "./BasketItem";
import moment from "moment";
import { IBasket } from "./IBasket";


export class Basket implements IBasket
{
    public Items = new Collection<BasketItem>();
    public TotalPrice = new RefNumber()
    public TotalDeposits = new RefNumber()
    public IsAnythingToRent = new RefBool()

    public GetItem(index: number): BasketItem
    {
        return this.Items.Items[index];
    }
    public get DatesRange(): DatesRange
    {
        return this.rentTime;
    }
    public GetDepositsSum()
    {
        return this.TotalDeposits.value;
    }
    public Clear()
    {
        this.Items.Clear()
    }

    public CanAdd(type: BasketItemType, productId: string, optionId: string): boolean
    {
        return !!!this.Items.Items.find(x => x.Type == type && x.ProductId == productId && x.OptionId == optionId);
    }

    public Add(type: BasketItemType, productId: string, optionId: string = "", quantity = 1): boolean
    {
        if (!this.CanAdd(type, productId, optionId))
        {
            // console.log("Produkt jest już w koszyku")
            return false;
        }

        let item: BasketItem;

        const product = this._products.find(x => x.Id == productId)

        if (!product)
        {
            // console.log("Produkt przestał istnieć w bazie?")
            return false;
        }

        switch (type)
        {
            case BasketItemType.Rent:
                item = new RentBasketItem(this.rentTime, product);
                break;
            case BasketItemType.Sale:
            case BasketItemType.Service:
            case BasketItemType.Help:
                item = new SaleBasketItem(product);
                break;
            default: throw new Error(`Could not convert basket item from raw to instance`)
        }

        item.Type = type;
        item.ProductId = productId;
        item.OptionId = optionId;
        item.Quantity.value = quantity;
        item.Update();

        this.Items.Add(item);

        item.Quantity.OnChange(() => this.Update());

        this.Update();

        return true;
    }

    constructor(private _products: Product[], private _storage: IBasketStorage, private rentTime: DatesRange)
    {
        this.Items.OnChange(items => _storage.Update(items))

        const storedItems: BasketStorageItem[] = _storage.Get();

        if (storedItems.length > 0)
        {
            // console.log(storedItems)
            storedItems.forEach(x => this.Add(x.Type, x.ProductId, x.Option, x.Quantity))
        }
    }

    private Update(): void
    {
        this.IsAnythingToRent.value = this.Items.Items.some(x => x.Type == BasketItemType.Rent)
        this.TotalPrice.value = this.Items.Items.reduce((prev, cur) => prev + cur.FinalPrice.value, 0)
        this.TotalDeposits.value = this.Items.Items.reduce((prev, cur) => prev + cur.FinalDeposit.value, 0)
        this._storage.Update(this.Items.Items)
    }

    public get RentStartAsString(): string
    {
        return moment(this.rentTime.Start.value).format("dddd DD.MM.YYYY");
    }
    public get RentEndAsString(): string
    {
        return moment(this.rentTime.End.value).format("dddd DD.MM.YYYY");
    }
}
