import { Div, Image, Span } from "@tblabs/truffle";
import { ProductCategory } from "../../../Models/Product/ProductCategory";


export class ProductCategoryBox extends Div
{
    constructor(category: ProductCategory, priceFrom: string)
    {
        super("ProductCategoryBox");
        
        this.OnClick(() => window.location.hash = "category/" + category.Url.value)

        this.Append(
            new Image(category.Image.value).Class("img"),
            new Div().Text(category.Title.value).Class("title"),
            new Div().Text(category.Subtitle.value).Class("subtitle"),
            new Span(priceFrom).Class("price"),
        )
    }
}
