import { Collection } from "@tblabs/truffle";
import { ProductOption } from "../Description/Opinion/ProductOption";


export class ProductOptions
{
    public List = new Collection<ProductOption>();

    public GetFactor(optionId: string): number
    {
        return this.List.Items.find(x => x.Url.value == optionId)?.PriceFactor?.value ?? 1;
    }
}
