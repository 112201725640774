import { Div, Link } from "@tblabs/truffle";
import { HashLink } from "../../Components/Utils/HashLink";
import { Center } from "../../Components/Utils/Center";


export class PageFooter extends Div
{
    constructor()
    {
        super("page-footer");

        this.Append(
            new Center(
                // new Div("centered").TextAlignRight().Height(32).Background("#7a7a7a").Append(
                // ),
                new Div("centered").TextAlignRight().Append(
                    new Link("⛽ Praca", "https://recruit.specteam.pl/#jobs").Class("hash-link"),
                    new HashLink("📰 Blog", "blog"),
                    new HashLink("🐓 Zaloguj", "admin/login"),
                ),
            ),
        )
    }
}
