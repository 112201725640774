import { Collection } from "@tblabs/truffle";
import { BlogArticle } from "../Blog/BlogArticle";
import { Field } from "../Description/Field";
import { Product } from "../Product/Product";
import { ProductCategory } from "../Product/ProductCategory";


export class WebsiteContent
{
  
    public ProductCategories = new Collection<ProductCategory>();
    public Products = new Collection<Product>();
    public AboutUs = new Collection<Field>();
    public Contact = new Collection<Field>();
    public Blog = new Collection<BlogArticle>();

    public get ProductCategoriesAsTitleIdObject(): object
    {
        return this.ProductCategories.Items.reduce((prev, curr: ProductCategory) => ({ ...prev, [curr.Title.value]: curr.Id.value }), {});
    }

    public CategoryByUrl(url: string): ProductCategory
    {
        const category = this.ProductCategories.Items.find(x => x.Url.value == url);

        if (!category)
            // throw new Error(`Category not found`);
            return ProductCategory.NotFound;

        return category;
    }

    public ProductByUrl(url: string): Product
    {
        const product = this.Products.Items.find(x => x.Url.value == url);

        if (!product)
            throw new Error(`Product not found`);     

        return product;
    }

    public PrepareProducts()
    {
        // Set Category for each product
        this.Products.Items.forEach(product =>
        {
            const category = this.ProductCategories.Items.find(x => x.Id.value == product.CategoryId.value)
            if (!category)
            throw new Error(`Category "${product.CategoryId.value}" not found`);

            product.Category = category;
        });
    }

    public ProductsByCategory(category: ProductCategory): Product[]
    {
        return this.Products.Items.filter(x => x.CategoryId.value == category?.Id.value);
    }
}
