import { Product } from "../../Models/Product/Product";
import { DiscountCalculator } from "../RentPriceCalculator";
import { BasketItem } from "./BasketItem";


export class SaleBasketItem extends BasketItem
{
    constructor(private product: Product)
    {
        super(product);

        this.Quantity.OnChange(quantity => this.Update());

        this.Update();
    }

    public Update(): void
    {
        const quantity = this.Quantity.value;
        const salePrice = this.product.SalePrice.value;
        const discounts = this.product.Discounts.Items.map(x => ({ quantity: x.Quantity.value, discountPercent: x.Percent.value }));
        const { discountMultiplier, discountPercent } = DiscountCalculator.Calc(discounts, quantity);
        const optionFactor = this.product.Options.GetFactor(this.OptionId);
       
        this.FinalPrice.value = +(quantity * salePrice * discountMultiplier * optionFactor).toFixed(0);
        this.Label.value = `Zakup`;
        this.DiscountLabel.value = `${discountPercent}% zniżki`;
        this.FinalDeposit.value = quantity * this.product.Deposit.value;
        this.PriceLabel.value = `${this.product.SalePrice.value}zł/szt`;
    }
}
