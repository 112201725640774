import { Collection } from "@tblabs/truffle";

export interface RawPayment
{
    Type: string;
    Amount: number;
    Currency: string;
    Form: string;
    PaymentDeadline: Date;
    // WhenPaid: Date;
    // Comment: string;
    VisibleForCustomer: boolean;
    Status: string;
    // Sender: string; // ?
    // Receiver: string; // ?
}


export class TicketPayment
{
    public Payments = new Collection<RawPayment>();
}
