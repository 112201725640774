import { Div, RefBool, Label } from "@tblabs/truffle";
import { Basket } from "../../Services/Basket/Basket";


export class OpenBasketButton extends Div
{
    constructor(basket: Basket, showMiniBasket: RefBool)
    {
        super();
        this.FloatRight()

        this.Append(
            new Label(basket.Items.CountRef, v => `🛒 Koszyk (${v})`)
                .MarginLeft(32).Color("#f8f8f8").CursorPointer()
                .OnClick(() => showMiniBasket.Toggle())
        )
    }
}
