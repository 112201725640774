import { Div, Image, Label, Link, Span } from "@tblabs/truffle";
import { BasketItem } from "../../Services/Basket/BasketItem";


export class ProductView extends Div
{
    constructor(item: BasketItem)
    {
        super();

        this.Append(
            new Image(item.Product.Images.Items?.[0]?.MiniUrl || "").Height(44).Width(66).Margin(8).FloatLeft(),
            new Div().TextAlignLeft().FloatLeft().MarginTop(8).Append(
                new Div().Append(
                    new Link(item.Product.BasketName || "", `#product/${item.Product.Url.value}/${item.OptionId}`),
                    item.OptionId && new Span(`(${item.OptionId})`).MarginLeft(4).Color("#666").FontSize(13),
                ),
                new Label(item.PriceLabel).Color("#999").FontSize(12)
            )
        )
    }
}
