import { HandlersCollection, RefDate, RefNumber } from "@tblabs/truffle";
import moment from "moment";


export class DatesRange
{
    public DaysCount = new RefNumber()
    private onChange = new HandlersCollection<(start: Date, end: Date, days: number) => void>();

    constructor(public Start: RefDate, public End: RefDate)
    {
        Start.value = moment(Start.value).hour(12).minute(0).toDate();
        End.value = moment(End.value).hour(12).minute(0).toDate();

        Start.OnChange(v =>
        {
            this.DaysCount.value = moment(End.value).diff(v, 'days') + 1;
            if (this.DaysCount.value <= 0)
                End.value = Start.value;

            this.onChange.Call(v, End.value, this.DaysCount.value);
        })
        End.OnChange(v =>
        {
            this.DaysCount.value = moment(v).diff(Start.value, 'days') + 1;
            if (this.DaysCount.value <= 0)
                Start.value = End.value;

            this.onChange.Call(Start.value, v, this.DaysCount.value);
        }, true)
    }

    public AddDay()
    {
        this.End.value = moment(this.End.value).add(1, 'day').toDate();
    }

    public SubtractDay()
    {
        this.End.value = moment(this.End.value).subtract(1, 'day').toDate();
    }

    public OnChange(handler: (start: Date, end: Date, days: number) => void)
    {
        this.onChange.Add(handler);
    }
}
