import { Ref, RefString, RefBool, Collection } from "@tblabs/truffle";
import { DeliveryForm } from "../../Models/Basket/DeliveryForm";
import { ReturnForm } from "../../Models/Basket/ReturnForm";
import { FormPaymentForm } from "../../Models/OrderTicket/PaymentForm";
import { Basket } from "../../Services/Basket/Basket";
import { IBasket } from "../../Services/Basket/IBasket";
import { RefWatcher } from "../../Utils/RefWatcher";
import { CustomerInfo } from "./CustomerInfo";
import { Ticket } from "../../Models/OrderTicket/Ticket";
import { BasketItemStatus, RawRentTicketBasketItem, RawSaleTicketBasketItem, RawTicketBasketItem } from "../../Models/OrderTicket/RawTicketBasketItem";
import { BasketItem } from "../../Services/Basket/BasketItem";
import { BasketItemType } from "../../Models/Basket/BasketItemType";
import { v4 as uuidv4 } from 'uuid';
import moment from "moment";
import { RawPayment } from "../../Models/OrderTicket/TicketPayment";
import { PaymentType } from "../../Models/OrderTicket/PaymentType";
import { RawPackageTransit, RawPersonalTransit, RawTaxiTransit } from "../../Models/OrderTicket/RawTransit";
import { RawTransit } from "../../Models/OrderTicket/RawTransit";
import { TransitType } from "../../Models/OrderTicket/TransitType";
import { TransitDirection } from "../../Models/OrderTicket/TransitDirection";
import { DispatchTimeCalculator } from "../../Services/DispatchTimeCalculator";
import { TrainingType } from "../../Models/OrderTicket/TrainingType";
import { RawTraining } from "../../Models/OrderTicket/RawTraining";
import { TrainingStatus } from "../../Models/OrderTicket/TrainingStatus";
import { Costs } from "./Costs";
import { TransitStatus } from "../../Models/OrderTicket/TransitStatus";
import { PaymentStatus } from "../../Models/OrderTicket/PaymentStatus";

export enum PaymentForm
{
    Unset = "Unset",
    Cash = "Cash",
    BankTransfer = "BankTransfer",
    CashAtDelivery = "CashAtDelivery",
    TransferAtDelivery = "TransferAtDelivery", // Przelew wykonany przez kuriera w momencie odbioru paczki
    PostalTransfer = "PostalTransfer", // Kasa nadana na poczcie w formie przelewu
    Crypto = "Crypto",
}


export class FormInfoToTicketConverter
{
    public Convert(info: FormInfo): Ticket
    {
        const ticket = new Ticket(uuidv4());

        ticket.People.People.Add(info.Customer);
        ticket.Basket.Items.Add(... this.MakeBasket(info.basket.Items));
        ticket.Payment.Payments.Add(...this.MakePayments(info));
        ticket.Transit.Transits.Add(...this.MakeTransits(info));
        ticket.Training.Trainings.Add(...this.MakeTraining(info));

        return ticket;
    }

    private MakeTraining(info: FormInfo): RawTraining[]
    {
        const trainings: Partial<RawTraining>[] = [];

        if (info.deliveryForm.value == DeliveryForm.PersonalWithTraining)
        {
            trainings.push({
                Type: TrainingType.Personal,
                Label: "Szkolenie odbędzie się podczas odbioru sprzętu",
                Status: TrainingStatus.Undone,
            });
        }

        trainings.push({
            Type: TrainingType.ViaTelephone,
            Label: "Zadzwoń pod numer (+48) 507-293-714",
            Status: TrainingStatus.Undone,
        });

        info.basket.Items.Items.forEach(item =>
        {
            const label = `Materiały szkoleniowe dla ${item.Product.BasketName.value}`;
            if (!trainings.find(t => t.Label == label))
            {
                trainings.push({
                    Type: TrainingType.Self,
                    Label: label,
                    Status: TrainingStatus.AvailableSoon,
                    Link: item.Product.TutorialLink.value
                });
            }
        });

        return trainings as RawTraining[];
    }

    private MakeTransits(info: FormInfo): RawTransit[]
    {
        const transits: RawTransit[] = [];

        switch (info.deliveryForm.value)
        {
            case DeliveryForm.Pocztex:
            case DeliveryForm.Inpost:
                transits.push({
                    Type: TransitType.Package,
                    Direction: TransitDirection.ToCustomer,
                    PlannedDispatchTime: info.basket.IsAnythingToRent
                        ? DispatchTimeCalculator.Calc(info.basket.DatesRange.Start.value) : new Date(0),
                    Status: TransitStatus.NotPlannedYet,
                } as RawPackageTransit)
                break;
            case DeliveryForm.PersonalWithTraining:
            case DeliveryForm.PersonalWithoutTraining:
                transits.push({
                    Type: TransitType.Personal,
                    Direction: TransitDirection.ToCustomer,
                    PlannedMeetingTime: info.basket.IsAnythingToRent
                        ? moment(info.basket.DatesRange.Start.value).subtract(1, 'day').toDate() : new Date(0),
                    Status: TransitStatus.NotPlannedYet,
                } as RawPersonalTransit)
                break;
            default:
                throw new Error(`Unhandled delivery form`);
        }

        switch (info.returnForm.value)
        {
            case ReturnForm.Package:
                transits.push({
                    Type: TransitType.Package,
                    Direction: TransitDirection.FromCustomer,
                    PlannedDispatchTime: info.basket.IsAnythingToRent
                        ? info.basket.DatesRange.End.value : new Date(0),
                    Status: TransitStatus.NotPlannedYet,
                } as RawPackageTransit)
                break;
            case ReturnForm.Personal:
                transits.push({
                    Type: TransitType.Personal,
                    Direction: TransitDirection.FromCustomer,
                    PlannedMeetingTime: info.basket.IsAnythingToRent
                        ? info.basket.DatesRange.End.value : new Date(0),
                    Status: TransitStatus.NotPlannedYet,
                } as RawPersonalTransit)
                break;
            case ReturnForm.Taxi:
                transits.push({
                    Type: TransitType.Taxi,
                    Direction: TransitDirection.FromCustomer,
                    When: info.basket.IsAnythingToRent
                        ? info.basket.DatesRange.End.value : new Date(0),
                    Status: TransitStatus.NotPlannedYet,
                } as RawTaxiTransit)
                break;
            default:
                throw new Error(`Unhandled return form`);
        }

        return transits;
    }

    private MakePayments(info: FormInfo): RawPayment[]
    {
        const payments: Partial<RawPayment>[] = [];

        let depositPaymentForm = PaymentForm.Unset;
        let depositReturnPaymentForm = PaymentForm.Unset;
        let basketPaymentForm = PaymentForm.Unset;
        let basketPaymentStatus = PaymentStatus.NotPaid;
        let currency = "";

        switch (info.paymentForm.value)
        {
            case FormPaymentForm.Cash:
                basketPaymentForm = PaymentForm.Cash;
                depositPaymentForm = PaymentForm.Cash;
                depositReturnPaymentForm = info.returnForm.Is(ReturnForm.Personal) ? PaymentForm.Cash : PaymentForm.BankTransfer;
                currency = "PLN";
                basketPaymentStatus = PaymentStatus.NotPaid;
                break;
            case FormPaymentForm.DepositPrepaidServicePostpaid:
                depositPaymentForm = PaymentForm.BankTransfer;
                depositReturnPaymentForm = PaymentForm.BankTransfer;
                basketPaymentForm = PaymentForm.CashAtDelivery;
                currency = "PLN";
                basketPaymentStatus = PaymentStatus.NotPaid;
                break;
            case FormPaymentForm.Crypto:
                depositPaymentForm = PaymentForm.Crypto;
                depositReturnPaymentForm = PaymentForm.Crypto;
                basketPaymentForm = PaymentForm.Crypto;
                currency = "BTC";
                basketPaymentStatus = PaymentStatus.Awaiting;
                break;
            default:
                throw new Error(`Unhandled payment form`);
        }

        const rentStart = info.basket.DatesRange.Start.value;
        const rentEnd = info.basket.DatesRange.End.value;

        let basketPaymentDeadline = new Date(0);
        if (info.deliveryForm.Is(DeliveryForm.Pocztex))
        {
            basketPaymentDeadline = moment(rentEnd).add(14, 'days').toDate() // Przypomnienie dla Agenta że kasa idzie z poczty
        }

        payments.push({
            Type: PaymentType.Basket,
            PaymentDeadline: basketPaymentDeadline,
            Amount: info.costs.GetNonReturnableCostsSum(),
            Currency: currency,
            Form: basketPaymentForm,
            VisibleForCustomer: true,
            Status: basketPaymentStatus,
        })

        if (info.basket.IsAnythingToRent.value)
        {
            let depositReturnPaymentDeadline = moment(rentEnd).add(7, 'days').toDate()

            payments.push({
                Type: PaymentType.Deposit,
                PaymentDeadline: rentStart,
                Amount: info.costs.GetReturnableCostsSum(),
                Currency: currency,
                Form: depositPaymentForm,
                VisibleForCustomer: true,
                Status: PaymentStatus.Awaiting,
            })
            payments.push({
                Type: PaymentType.DepositReturn,
                PaymentDeadline: depositReturnPaymentDeadline,
                Amount: info.costs.GetReturnableCostsSum(),
                Currency: currency,
                Form: depositReturnPaymentForm,
                VisibleForCustomer: true,
                Status: PaymentStatus.Quequed,
            })
        }

        return payments as RawPayment[];
    }

    private MakeBasket(basketItems: Collection<BasketItem>): RawTicketBasketItem[]
    {
        return basketItems.Items.map(x =>
        {
            let ticketBasketItem: RawSaleTicketBasketItem | RawRentTicketBasketItem = {
                Type: x.Type,
                Product: {
                    ProductId: x.Product.Id,
                    Name: x.Product.BasketName.value,
                    Origin: window.location.origin,
                    Link: `#product/${x.Product.Url.value}`,
                    Picture: x.Product.Images.Items[0].MiniUrl.value,
                    Tutorial: x.Product.TutorialLink.value,
                    Prices: x.Product.RentPrices.Items.map(x => `${x.Value.value}zł/${x.Days.value}dni`).join(" ; ") + " ; " + x.Product.SalePrice.value + "zł/szt",
                },
                TotalPrice: x.FinalPrice.value,
                Quantity: x.Quantity.value,
                Status: BasketItemStatus.AwaitingConfirmation,
            };

            switch (x.Type)
            {
                case BasketItemType.Rent:
                    ticketBasketItem = {
                        ...ticketBasketItem,
                        Timeline: {
                            Start: x.RentStart,
                            End: x.RentEnd,
                        },
                        Deposit: x.Deposit,
                    }
                    break;
            }

            return ticketBasketItem;
        });
    }
}

export class FormInfo
{
    public Customer = new CustomerInfo();
    public paymentForm = new Ref<FormPaymentForm>(FormPaymentForm.Unset).Storable("payment-form");
    public deliveryForm = new Ref<DeliveryForm>(DeliveryForm.Unset).Storable("delivery-form");
    public returnForm = new Ref<ReturnForm>(ReturnForm.Unset).Storable("return-form");

    public depositInfo = new RefString();
    public depositReturnInfo = new RefString();
    public trainingInfo = new RefString();
    public allowCashPayment = new RefBool();
    public showCustomerDepositAccountInput = new RefBool();
    public place = new RefString("Warszawa/Wola");

    public costs = new Costs(this.basket, this.paymentForm, this.deliveryForm);


    constructor(public basket: IBasket)
    {
        RefWatcher.Watch([this.deliveryForm, this.paymentForm, this.returnForm, basket.Items], () =>
        {
            const personalDelivery = [DeliveryForm.PersonalWithTraining, DeliveryForm.PersonalWithoutTraining].includes(this.deliveryForm.value);
            const personalReturn = [ReturnForm.Personal].includes(this.returnForm.value);

            // 🎓 Training
            this.trainingInfo.value = {
                [DeliveryForm.Pocztex]: `Szkolenie odbędzie się przez telefon. Otrzymasz także materiały do samodzielnej nauki`,
                [DeliveryForm.PersonalWithTraining]: `Szkolenie odbędzie się na miejscu. Otrzymasz także materiały do samodzielnej nauki`,
                [DeliveryForm.PersonalWithoutTraining]: `Szkolenie nie odbędzie się na miejscu. Zamiast tego otrzymasz materiały do samodzielnej nauki oraz dostęp do darmowej infolinii`,
            }[this.deliveryForm.value] || "...";

            // Package + Cash is not possible, so switch to the next available option
            if (this.deliveryForm.Is(DeliveryForm.Pocztex) && this.paymentForm.Is(FormPaymentForm.Cash))
                this.paymentForm.value = FormPaymentForm.DepositPrepaidServicePostpaid;
            // Nothing to rent + Personal delivery + Cash at delivery is not possible, so switch to the next available option
            if (!basket.IsAnythingToRent && !this.deliveryForm.Is(DeliveryForm.Pocztex) && this.paymentForm.Is(FormPaymentForm.CashAtDelivery))
                this.paymentForm.value = FormPaymentForm.Cash;

            // 💳 Payment
            this.allowCashPayment.value = personalDelivery;

            // 💰 Deposit
            this.depositInfo.value = {
                [FormPaymentForm.Cash]: `Kaucja zostanie pobrana gotówką podczas odbioru sprzętu`,
                [FormPaymentForm.DepositPrepaidServicePostpaid]: `Łączna suma kaucji wynosi ${basket.GetDepositsSum()}zł. Płatne z góry przelewem`,
                [FormPaymentForm.PartialDepositPrepaidServicePostpaid]: `Łączna suma kaucji wynosi ${basket.GetDepositsSum()}zł, z czego 60zł jest płatne z góry przelewem, pozostała kwota kaucji zostanie doliczona do kwoty pobrania`,
                [FormPaymentForm.FromPreviousOrder]: `Wykorzystamy kaucję z Twojego poprzedniego zamówienia o ile to możliwe (wartość kaucji jest taka sama i poprzednie zamówienie nie zostało jeszcze rozliczone)`,
                [FormPaymentForm.Crypto]: `Kaucja zostanie wliczona w koszt przelewu`,
                [FormPaymentForm.Transfer]: `Kwota kaucji zostanie doliczona do kwoty przelewu`
            }[this.paymentForm.value] || "...";

            // 💰 Deposit return
            this.showCustomerDepositAccountInput.value = personalDelivery && !personalReturn && this.paymentForm.Is(FormPaymentForm.Cash);

            this.depositReturnInfo.value = {
                [FormPaymentForm.Cash]: `Kaucja zostanie zwrócona na miejscu w gotówce`,
                [FormPaymentForm.Transfer]: `Kaucja zostanie zwrócona na konto, z którego przyszła`,
                [FormPaymentForm.DepositPrepaidServicePostpaid]: `Kaucja zostanie odesłana na konto, z którego przyszła`,
                [FormPaymentForm.PartialDepositPrepaidServicePostpaid]: `Kaucja zostanie zwrócona w dwóch przelewach`,
                [FormPaymentForm.Crypto]: `Kaucja zostanie zwrócona na wskazany portfel (wyliczona z proporcji)`,
                [FormPaymentForm.FromPreviousOrder]: `Jako że kaucja została opłacona w poprzednim zamówieniu - zostanie zwrócona w sposób zgodny z formą jej opłacenia`,
            }[this.paymentForm.value] || "...";

            this.costs.Update();
        });
    }
}
