import { Ref, Link, Line } from "@tblabs/truffle";
import { DeliveryFormValueToLabel } from "../../Models/Basket/DeliveryForm";
import { ReturnFormValueToLabel } from "../../Models/Basket/ReturnForm";
import { Ticketer } from "../../Services/Repo/TicketSender";
import { Box } from "../Utils/Box";
import { Center } from "../Utils/Center";
import { DangerButton } from "../Utils/DangerButton";
import { InputRow } from "../Utils/InputRow";
import { Loader } from "../Utils/Loader";
import { Section } from "../Utils/Section";
import { CostsSummaryTable } from "./CostsSummaryTable";
import { FormInfo } from "./FormInfo";
import { OrderFormView } from "./OrderFormView";


export class SummaryBox extends Box
{
    constructor(private info: FormInfo, private view: Ref<OrderFormView>)
    {
        const returnToEditBtn = new Link("Wróć do edycji").OnClick(() => view.value = OrderFormView.Form).MarginLeft(32);

        // const loader = new Loader("Wysyłanie...").DisplayInline().Hide();

        const basket = info.basket;

        super(
            new Section("🧮 Podsumowanie kosztów", [
                new CostsSummaryTable(info),
            ]),
            basket.IsAnythingToRent.value && new Section("🚚 Dostawa i zwrot", [
                new InputRow("Dostawa", DeliveryFormValueToLabel[info.deliveryForm.value], ", ", basket.RentStartAsString),
                basket.IsAnythingToRent.value && new InputRow("Zwrot", ReturnFormValueToLabel[info.returnForm.value], ", ", basket.RentEndAsString),
            ]),
            !basket.IsAnythingToRent.value && new Section("🚚 Dostawa", [
                new InputRow("Dostawa", DeliveryFormValueToLabel[info.deliveryForm.value]),
            ]),
            basket.IsAnythingToRent.value && new Section("💰 Kaucja", [
                new InputRow("Wpłata", info.depositInfo.value),
                new InputRow("Zwrot", info.depositReturnInfo.value),
            ]),
            new Section("👦 Twoje dane", [
                new InputRow("Imię i nazwisko", info.Customer.Name.value),
                new InputRow("Kontakt", info.Customer.Phone.value, ", ", info.Customer.Email.value),
                new InputRow("Adres", info.Customer.Address.value),
            ]),
            new Line(),
            new Center(
                new DangerButton("Wyślij zgłoszenie").OnClick(async (btn) =>
                {
                    btn.Disabled();
                    returnToEditBtn.Hide();
                    // loader.Show();

                    // await this.Send();
                    view.value = OrderFormView.Sending;
                }),
                // loader,
                returnToEditBtn
            ).Margin(32)
        )
    }
}
