import { RefSerializer } from "@tblabs/truffle";
import { Ticket } from "../../Models/OrderTicket/Ticket";
import { StorageResponse } from "../Storage/Models/StorageResponse";
import { OnlineStorage } from "../Storage/OnlineStorage";
import { UpdateCommand } from "./UpdateCommand";


export class TicketSender
{
    private FOLDER = "SpecteamOrders";

    constructor(private _storage: OnlineStorage)
    { }

    public async Send(order: Ticket): Promise<StorageResponse>
    {
        const file = order.Id;
        const raw = RefSerializer.Flatenize<object>(order);
        const result = await this._storage.SendMessage(new UpdateCommand(file, this.FOLDER, raw, true));

        return result;
    }
}
