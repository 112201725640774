import { Ref, RefString, Radio, Select } from "@tblabs/truffle";
import { DeliveryForm } from "../../Models/Basket/DeliveryForm";
import { InputRow } from "../Utils/InputRow";


export class DeliveryFormSelector extends InputRow
{
    constructor(deliveryForm: Ref<DeliveryForm>, place: RefString)
    {
        super("Forma",
            new Radio(deliveryForm).Class("radio")
                .Add(DeliveryForm.Pocztex, "Kurier Pocztex pobranie (+1zł)")
                .Add(DeliveryForm.PersonalWithoutTraining, [
                    "Odbiór osobisty bez szkolenia (+1zł)",
                    new Select(place, ["Warszawa/Wola", "Warszawa/Mokotów"]).MarginLeft(12).WidthAuto()
                ], x => { x.Label.MarginLeft(0); })
                .Add(DeliveryForm.PersonalWithTraining, "Odbiór osobisty ze szkoleniem Warszawa/Wola (+51zł)")
                .Add(DeliveryForm.Inpost, "Paczkomat Inpost (+1zł)", x => x.Disabled())
                // .Add(DeliveryForm.Rentomat, "Rentomat (-49zł)", x => x.Disabled())
        );
    }
}
