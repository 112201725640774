import { RefNumber, RefSerializer } from "@tblabs/truffle";
import { DatesRange } from "../../Models/Dates/DatesRange";
import { Product } from "../../Models/Product/Product";
import { RentPriceCalculator, DiscountCalculator } from "../RentPriceCalculator";
import { BasketItem } from "./BasketItem";


export class RentBasketItem extends BasketItem
{
    public RentStart: Date = new Date(0);
    public RentEnd: Date = new Date(0);
    public Deposit: number = 0;

    constructor(private rentTime: DatesRange, private product: Product)
    {
        super(product);

        rentTime.OnChange((start, end, days) => this.Update());
        this.Quantity.OnChange(quantity => this.Update());

        this.Update();
    }

    public Update(): void
    {
        this.RentStart = this.rentTime.Start.value;
        this.RentEnd = this.rentTime.End.value;
        this.Deposit = this.product.Deposit.value;

        const quantity = this.Quantity.value;
        const rentDays = this.rentTime.DaysCount.value;
        const rentPrices = this.product.RentPrices.Items.map(x => ({ price: x.Value.value, days: x.Days.value }));
        const discounts = this.product.Discounts.Items.map(x => ({ quantity: x.Quantity.value, discountPercent: x.Percent.value }));
        const optionFactor = this.product.Options.GetFactor(this.OptionId);
        const rentPrice = RentPriceCalculator.Calc(rentPrices, rentDays);
        const { discountMultiplier, discountPercent } = DiscountCalculator.Calc(discounts, quantity);
        
        this.FinalPrice.value = +(quantity * rentPrice * discountMultiplier * optionFactor).toFixed(0);
        this.Label.value = `Wypożyczenie na ${rentDays} dni`;
        this.DiscountLabel.value = `${discountPercent}% zniżki`;
        this.FinalDeposit.value = quantity * this.product.Deposit.value;
        this.PriceLabel.value = this.product.RentPrices.Items.filter(x => x.Visible.value).map(x => `${x.Value.value}zł/${x.Days.value}dni`).join(", ");
    }
}
