import { TableBuilder, Label, Link } from "@tblabs/truffle";
import { PlusMinusInput } from "./Inputs/PlusMinusInput";
import { ProductView } from "./Product/ProductView";
import { Center } from "./Utils/Center";
import { IBasket } from "../Services/Basket/IBasket";
import { BasketItem } from "../Services/Basket/BasketItem";


export class BasketTable extends TableBuilder<BasketItem>
{
    constructor(basket: IBasket)
    {
        super(basket.Items);

        this.WidthAuto().MarginAuto()

        this.AddColumn("Usługa", p => new Label(p.Label))
            .AddColumn("Produkt", p => new ProductView(p))
            .AddColumn("Ilość", p => [
                new PlusMinusInput(p.Quantity).MarginBottom(0),
                new Label(p.DiscountLabel).FontSize(14).MarginTop(0)
            ])
            .AddColumn("Koszt", p => new Label(p.FinalPrice, v => v + " zł").Bold().DisplayInlineBlock().Width(70))
            .AddColumn("", (x, row) =>
            {
                row.TextAlignLeft();
                return [
                    new Link("❌ Usuń").NoDecorationOnHover().OnClick(() => basket.Items.Remove(x)),
                ];
            })
            .ForEachRow(row => row.Background("#fafafa"))
            .WhenEmpty(new Center("Brak produktów").MarginTopBottom(32))
            .Build();
    }
}
