import { Div, Span, Button, RefString, Label } from "@tblabs/truffle";
import { Center } from "../../Components/Utils/Center";
import { Product } from "../../Models/Product/Product";
import { DangerButton } from "../../Components/Utils/DangerButton";


export class SaleSection extends Div
{
    constructor(product: Product, onClick: (product: Product) => void, option: RefString)
    {
        super();

        const GetOptionFactor = () => product.Options.GetFactor(option.value);

        this.Append(
            new Label(option, _ => `${(product.SalePrice.value * GetOptionFactor()).toFixed(2)}zł brutto`).Bold().Color("#444"),
            new Label(option, _ => `≈ ${(product.SalePrice.value * GetOptionFactor() * 0.77).toFixed(2)}zł netto\n`).MarginLeft(8).Color("#999").FontItalic(),
            new Center(
                new Button("Kup").Class("selected").Width(140)
                    .OnClick(() => onClick(product))
            )
        )
    }
}

export class BasketableSection extends Div
{
    constructor(product: Product, onClick: (product: Product) => void, option: RefString)
    {
        super();

        const GetOptionFactor = () => product.Options.GetFactor(option.value);

        this.Append(
            new Label(option, _ => `${(product.SalePrice.value * GetOptionFactor()).toFixed(2)}zł brutto`).Bold().Color("#444"),
            new Label(option, _ => `≈ ${(product.SalePrice.value * GetOptionFactor() * 0.77).toFixed(2)}zł netto\n`).MarginLeft(8).Color("#999").FontItalic(),
             new Center(
                new DangerButton("Dodaj do koszyka", () => onClick(product))
            )
        )
    }
}
