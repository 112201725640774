import { Div, RefString, Image, Collection } from "@tblabs/truffle";
import { GalleryItem } from "../../../Models/Description/Gallery/GalleryItem";


export class Gallery extends Div
{
    constructor(images: Collection<GalleryItem>)
    {
        super("gallery");

        if (images.Count == 0)
        {
            this.Append("Brak zdjęć")
            return;
        }

        const previewPicture = new RefString(images.Items[0].ThumbnailUrl.value || images.Items[0].NormalizedUrl.value);
        const pictureUrl = new RefString(images.Items[0].NormalizedUrl.value);

        this.Append(
            new Image(previewPicture).Class("preview")
                .OnClick(() => window.open(pictureUrl.value, "_blank")),
            new Div("minis").Append(
                ...images.Items.map(x => new Image(x.ThumbnailUrl.value || x.NormalizedUrl.value, x.Description.value).Class("mini")
                    .OnClick(() =>
                    {
                        previewPicture.value = x.ThumbnailUrl.value || x.NormalizedUrl.value;
                        pictureUrl.value = x.NormalizedUrl.value;
                    })
                )
            )
        )
    }
}
