import { Div } from "@tblabs/truffle";
import { Row } from "../../Components/Utils/Row";


export class Feature extends Div
{
    constructor(text: string)
    {
        super("feature");

        this.Text(text)

        this.OnClick(() => window.location.hash = "o-nas")
    }
}

export class Features extends Div
{
    constructor()
    {
        super("features");

        this.Append(
            new Row(
                new Feature("BEZ UMOWY\nBEZ DOKUMENTÓW"),
                new Feature("UBEZPIECZENIE\nOD USZKODZEŃ"),
                new Feature("WSPARCIE\nSPECJALISTÓW"),
                new Feature("PRZESYŁKA\nW 24H"),
                new Feature("MINIMUM\n3 DNI W CENIE"),
            ).MarginTop(16)
        )
    }
}
