import { Div, Span, Button, Label, RefString, RefSerializer } from "@tblabs/truffle";
import { Center } from "../../Components/Utils/Center";
import { Product } from "../../Models/Product/Product";


export class RentSection extends Div
{
    constructor(product: Product, onClick: (product: Product) => void, option: RefString)
    {
        super();

        const GetOptionFactor = () => product.Options.GetFactor(option.value);

        this.Append(
            ...product.RentPrices.Items.filter(x => x.Visible.value)
                .map(x => new Div().Append(
                    new Label(option, _ => `${(x.Value.value * GetOptionFactor()).toFixed(0)}zł / ${x.Days.value} dni`).Bold().Color("#444"),
                    new Label(option, _ => `≈ ${((x.Value.value * GetOptionFactor()) / x.Days.value).toFixed(0)}zł / dzień`).MarginLeft(8).Color("#999").FontItalic()
                )),
            new Span(`ℹ️ Minimalny okres najmu: 3 dni`).FontSize(12),
            "\n",
            new Span(`ℹ️ Kaucja zwrotna: ${product.Deposit.value}zł`).FontSize(12),
            new Center(
                new Button("Wypożycz").Class("selected").Width(140)
                    .OnClick(() => onClick(product))
            )
        )
    }
}
