import { Row } from "../../Components/Utils/Row";
import { AuthService } from "../../Services/Auth/AuthService";
import { WebsiteContentProvider } from "../../Services/Content/WebsiteContentProvider";
import { Loader } from "../../Components/Utils/Loader";
import { AdminEditButton } from "../../Components/Admin/AdminEditButton";
import { Page } from "../Components/Page";
import { DescriptionViewer } from "../../Components/Description/DescriptionViewer";
import { PageLogo } from "../Components/PageLogo";


export class ContactPage extends Page
{
    private loader = new Loader()
    private content = new Row()

    constructor(private _content: WebsiteContentProvider, private _user: AuthService)
    {
        super();

        this.body.Append(
            new PageLogo(),
            this.loader,
            this.content
        )
    }

    protected async OnAppend(): Promise<void>
    {
        const content = await this._content.Get();

        this.loader.RemoveSelf();

        this.content.Insert(
            this._user.IsAuthorized && new AdminEditButton(`edit/page`),
            new DescriptionViewer(content.Contact),
        )
    }
}
