import { Collection } from "@tblabs/truffle";
import { Transit } from "../Transit/Transit";




export class TicketTransit
{
    public Transits = new Collection<Transit>();
}
