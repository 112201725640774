import { Div, RefBool } from "@tblabs/truffle";
import { MiniBasketBox } from "../../Components/MiniBasket/MiniBasketBox";
import { Basket } from "../../Services/Basket/Basket";
import { HashLink } from "../../Components/Utils/HashLink";
import { OpenBasketButton } from "./OpenBasketButton";


export class PageHeader extends Div
{
    protected body = new Div();

    constructor(basket: Basket)
    {
        super("page-header");

        const showMiniBasket = new RefBool();

        this.body.Class("container").Append(
            new Div("left").Append(
                new HashLink("Oferta", "/"),
                new HashLink("Kontakt", "kontakt"),
                new HashLink("O nas", "o-nas"),
            ),
            new Div("right").Append(
                new HashLink("☎️ 507-293-714", "kontakt"),
                new OpenBasketButton(basket, showMiniBasket),
                new MiniBasketBox(basket, showMiniBasket),
            ),
        )

        this.Append(this.body)
    }
}
