import { RefSerializer } from "@tblabs/truffle";
import { TicketerResult } from "./TicketerResult";
import { FormInfo, FormInfoToTicketConverter } from "../../Components/OrderForm/FormInfo";
import { Delay } from "../../Utils/Delay";
import { TicketSender } from "./TicketSender.1";


export class Ticketer
{
    constructor(
        private _creator: FormInfoToTicketConverter,
        private _sender: TicketSender)
    { }

    public async Send(info: FormInfo): Promise<TicketerResult>
    {
        const ticket = this._creator.Convert(info);

        console.log(RefSerializer.Flatenize(ticket))

        // await Delay(3000)

        const storageResult = await this._sender.Send(ticket);

        const ticketUrl = process.env.ORDERS_WEB?.replace("{orderId}", ticket.Id) || "";
   
        return new TicketerResult(storageResult.IsSuccess, ticketUrl, storageResult.ErrorMessage);
    }
}