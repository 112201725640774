import { Div, Ref, TextInput, Span } from "@tblabs/truffle";


export class ClearableTextInput extends Div
{
    constructor(value: Ref<string>, placeholder: string)
    {
        super("ClearableTextInput");

        this.Append(
            new TextInput(value).Placeholder(placeholder),
            new Span("×").Class("x")
                .OnClick(() => value.value = "")
                .VisibleWhen(value, v => v.length > 0)
        )
    }
}
