import { Div, Image, Span } from "@tblabs/truffle";
import { Product } from "../../../Models/Product/Product";


export class ProductBox extends Div
{
    constructor(product: Product)
    {
        super("ProductBox");

        this.OnClick(() => window.location.hash = `product/${product.Url.value}`);

        let price = (-1);
        const visible = product.RentPrices.Items.filter(x => x.Visible.value);
        const last = visible[visible.length - 1];

        if (last)
            price = +(last.Value.value / last.Days.value).toFixed();

        this.Append(
            new Image(product.Images?.Items[0]?.ThumbnailUrl).Class("image"),
            new Div().Text(product.Title.value).Class("title"),
            new Div().Text(product.Subtitle.value).Class("subtitle"),
            new Span(`już od ${price}zł / dzień`).Italic().Padding(8).Class("starts-from")
        )
    }
}
