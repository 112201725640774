import { BasketItemType } from "../Basket/BasketItemType";
import { RawProductInfo } from "./RawProductInfo";

export enum BasketItemStatus
{
    Unset = "Unset",
    AwaitingConfirmation = "AwaitingConfirmation",
    InitiallyConfirmed = "InitiallyConfirmed",
    Available = "Available",
    Unavailable = "Unavailable",
    Rejected = "Rejected",
    InUse = "InUse",
    Malfunction = "Malfunction",
    Broken = "Broken",
    Returned = "Returned",
    NotReturned = "NotReturned",
}


export interface RawTicketBasketItem
{
    Type: BasketItemType;
    Product: Partial<RawProductInfo>;
    Quantity: number;
    TotalPrice: number;
    Status: BasketItemStatus;
}


// export interface RawTicketBasketItem
// {
//     Type: string;
//     // Status: string;
//     // Note: string;
//     Product: Partial<RawProductInfo>;
//     Quantity: number;
//     TotalPrice: number;
// }

export interface RawRentTicketBasketItem extends RawTicketBasketItem
{
    // ProductSerialNumber: string;
    Timeline: { Start: Date; End: Date; };
    Deposit: number;
}

export interface RawSaleTicketBasketItem extends RawTicketBasketItem
{
    // ProductSerialNumber: string;
}
