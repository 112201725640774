import { Ref } from "@tblabs/truffle";
import { Cost } from "../../Models/Basket/Cost";
import { DeliveryForm } from "../../Models/Basket/DeliveryForm";
import { Discount } from "../../Models/Basket/Discount";
import { ReturnableCost } from "../../Models/Basket/ReturnableCost";
import { FormPaymentForm } from "../../Models/OrderTicket/PaymentForm";
import { IBasket } from "../../Services/Basket/IBasket";



export class Costs
{
    public CRYPTO_DISCOUNT = 0.1;
    private costs: Cost[] = [];

    constructor(private basket: IBasket, private paymentForm: Ref<FormPaymentForm>, private deliveryForm: Ref<DeliveryForm>)
    {
        this.Update();
    }

    public get ListOfCosts(): Cost[]
    {
        return this.costs;
    }

    public Update(): void
    {
        this.costs = [];

        this.basket.Items.ForEach(x =>
        {
            this.Add(new Cost(`${x.Label.value}: ${x.Product.BasketName.value} (×${x.Quantity.value})`, x.FinalPrice.value));
        });

        let depositsSum = this.basket.GetDepositsSum();
        if (depositsSum)
        {
            this.Add(new ReturnableCost("Suma wszystkich kaucji", depositsSum));
        }

        if (this.deliveryForm.Is(DeliveryForm.PersonalWithTraining))
            this.Add(new Cost("Szkolenie z obsługi", 50));

        if ([DeliveryForm.Inpost, DeliveryForm.Pocztex].includes(this.deliveryForm.value))
            this.Add(new Cost("Dostawa", 1));

        if (this.paymentForm.Is(FormPaymentForm.Crypto))
        {
            const nonReturnableCosts = this.GetNonReturnableCostsSum();
            const cryptoDiscount = nonReturnableCosts * this.CRYPTO_DISCOUNT;
            this.Add(new Discount(`Zniżka za płatność w kryptowalucie (${this.CRYPTO_DISCOUNT * 100}%)`, cryptoDiscount));
        }
    }

    private Add(cost: Cost): void
    {
        this.costs.push(cost);
    }

    public GetNonReturnableCostsSum(): number
    {
        return this.costs.filter(x => x instanceof Cost).reduce((p, c) => p + c.Value, 0);
    }

    public GetReturnableCostsSum(): number
    {
        return this.costs.filter(x => x instanceof ReturnableCost).reduce((p, c) => p + c.Value, 0);
    }
}
